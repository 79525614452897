import React, {useEffect, useState} from "react";
import {ASSETS} from "../../constants/paths";
import {AnimationLoop} from "../../helper";

function TitleComponent() {

    let anim1 = React.createRef();
    let anim2 = React.createRef();

    const img_array = [
        "barbar_0157.png",
        "barbar_0158.png",
        "barbar_0159.png",
        "barbar_0160.png",
        "barbar_0161.png",
        "barbar_0162.png",
        "barbar_0163.png",
        "barbar_0164.png",
        "barbar_0165.png",
        "barbar_0166.png",
        "barbar_0167.png",
    ]

    let onLoad = () => {
        AnimationLoop(anim1.current.children, img_array, 0);
        AnimationLoop(anim2.current.children, img_array, 0);
    }


    return (
        <div className="title-container">
            <h1>
                <div className="row r1">THE</div>
                <div className="row r2">OTHER</div>
                <div className="row r3">BRICK</div>
                <div className="row r4">STUDIO</div>
            </h1>

            <div className="animation-block">
                <div ref={anim1} className="frame anim-1">
                    {img_array.map((name, i ) => <img key={i} src={ASSETS + "/img/png-sequence/" + name } alt={name} onLoad={i === img_array.length - 1 ? onLoad : null}/>)}
                </div>

                <div ref={anim2} className="frame anim-2">
                    {img_array.map((name, i ) => <img key={i} src={ASSETS + "/img/png-sequence/" + name } alt={name} onLoad={i === img_array.length - 1 ? onLoad : null}/>)}
                </div>
            </div>
        </div>
    );
}

export default TitleComponent;