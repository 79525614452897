import React, {Component} from "react";
import RoutesComponent from "./components/routesComponent";

class App extends Component{
  render() {
    return (
        <RoutesComponent/>
    );
  }
}

export default App;