import React, {useEffect, useRef, useState} from "react";
import {ASSETS} from "../../constants/paths";
import NavComponent from "../layout/navComponent";
import TitleComponent from "../layout/titleComponent";
import GamesComponent from "../layout/gamesComponent";
import ContactComponent from "../layout/contactComponent";
import FooterComponent from "../layout/footerComponent";

function LandingPageComponent(props) {

    const landingRootRef = useRef(null);
    const logoRef = useRef(null);
    const gamesRootRef = useRef(null);
    const contactRootRef = useRef(null);
    let [sticky, setSticky] = useState(false);

    const handleScroll = () => {
        setSticky(window.scrollY > logoRef.current.clientHeight)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToGames = () => gamesRootRef.current.scrollIntoView({ behavior: 'smooth' });
    const scrollToContact = () => contactRootRef.current.scrollIntoView({ behavior: 'smooth' });

    return (
        <div className="landing-root" ref={landingRootRef}>
            <div className="logo-container" ref={logoRef}>
                <img src={ASSETS + "/img/icon/icon.png"} alt={"the other brick studio logo"}/>
            </div>
            <NavComponent sticky={sticky} scrollToGames={scrollToGames} scrollToContact={scrollToContact}/>
            <TitleComponent/>
            <GamesComponent rootRef={gamesRootRef}/>
            <ContactComponent rootRef={contactRootRef}/>
            <FooterComponent/>
        </div>
    );
}

export default LandingPageComponent;