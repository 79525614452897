import React, {useEffect, useState} from "react";
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";

function FooterComponent() {

    return (
        <div className="footer-root">
            <Link className="link" to={"https://www.linkedin.com/company/the-other-brick-studio"} target="_blank" rel="noopener noreferrer">
                <img src={ASSETS + "/img/icon/logo-linkedin.svg"} alt={"steam"}/>
            </Link>
            <Link className="link" to={"https://twitter.com/theotherbrick"} target="_blank" rel="noopener noreferrer">
                <img src={ASSETS + "/img/icon/logo-twitter.svg"} alt={"steam"}/>
            </Link>
            <Link className="link" to={"https://www.instagram.com/theotherbrickstudio"} target="_blank" rel="noopener noreferrer">
                <img src={ASSETS + "/img/icon/logo-instagram.svg"} alt={"steam"}/>
            </Link>
            <Link className="link" to={"https://www.youtube.com/@TheOtherBrickStudio"} target="_blank" rel="noopener noreferrer">
                <img src={ASSETS + "/img/icon/logo-youtube.svg"} alt={"steam"}/>
            </Link>
        </div>
    );
}

export default FooterComponent;