import React from "react";
import LandingPageComponent from "./pages/landingPageComponent";
import {Navigate, Route, Routes} from "react-router-dom";
import * as ROUTES from "../constants/routePaths";

function RoutesComponent() {

    return (
        <>
            <Routes>
                <Route path={"*"} element={<Navigate replace to={ROUTES.LANDING_PAGE} />} />
                <Route path={ROUTES.LANDING_PAGE} element={<LandingPageComponent />} />
            </Routes>
        </>
    );
}

export default RoutesComponent;