import React, {useEffect, useState} from "react";
import {ASSETS} from "../../constants/paths";
import {AnimationLoop} from "../../helper";

function NavComponent({sticky, scrollToGames, scrollToContact}) {

    let anim1 = React.createRef();

    const img_array = [
        "barbar_0157.png",
        "barbar_0158.png",
        "barbar_0159.png",
        "barbar_0160.png",
        "barbar_0161.png",
        "barbar_0162.png",
        "barbar_0163.png",
        "barbar_0164.png",
        "barbar_0165.png",
        "barbar_0166.png",
        "barbar_0167.png",
    ]

    let onLoad = () => {
        AnimationLoop(anim1.current.children, img_array, 0);
    }

    return (
        <div className={`nav-container ${sticky ? "sticky" : ""}`}>
            <div className="nav-block">
                <img className="nav-item logo" src={ASSETS + "/img/icon/icon.png"} alt={"the other brick studio logo"}/>
                <div className="nav-item" onClick={scrollToGames}>
                    GAMES
                    <div ref={anim1} className="frame">
                        {img_array.map((name, i ) => <img key={i} src={ASSETS + "/img/png-sequence/" + name } alt={name} onLoad={i === img_array.length - 1 ? onLoad : null}/>)}
                    </div>
                </div>
                <div className="nav-item" onClick={scrollToContact}>CONTACT</div>
            </div>
        </div>
    );
}

export default NavComponent;