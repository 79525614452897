import React, {useEffect, useState} from "react";
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";

function GamesComponent({rootRef}) {

    return (
        <div className="games-root" ref={rootRef}>
            <h3>GAMES</h3>
            <div className="game-block">
                <div className="game-item">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/sT472FuvGHc?si=mQ_XLFlwfES52x03"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>

                    <Link className="link" to={"https://store.steampowered.com/app/2518280/The_Arena_Pit/"} target="_blank" rel="noopener noreferrer">
                        <img src={ASSETS + "/img/icon/logo-steam.svg"} alt={"steam"}/>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default GamesComponent;