
export const AnimationLoop = (elementList, img_array, activeIndex) => {
    elementList[activeIndex].style.opacity = "0";
    if (activeIndex === img_array.length - 1){
        activeIndex = 0;
    } else {
        activeIndex++;
    }
    elementList[activeIndex].style.opacity = "1";
    setTimeout(() => {AnimationLoop(elementList, img_array, activeIndex)}, 33)
}