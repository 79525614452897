import React, {useEffect, useState} from "react";
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";

function ContactComponent({rootRef}) {

    return (
        <div className="contact-root" ref={rootRef}>
            <h3>CONTACT</h3>

            <p>
                If you have a topic that is related to The Other Brick Studio as a company, don’t hesitate to get in contact with us! You can reach out to us on
                <Link className="link" to={"https://www.linkedin.com/company/the-other-brick-studio"} target="_blank" rel="noopener noreferrer"> LinkedIn.</Link>
            </p>
        </div>
    );
}

export default ContactComponent;